export const initialState = {
    company: {}
};

// ==============================|| AUTH REDUCER ||============================== //

const companyReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'SET_COMPANY_SELECTED':
            return {
                ...state,
                company: actions.payload
            };
        default:
            return state;
    }
};

export default companyReducer;

import { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Grid from '@mui/material/Grid';
import ErrorIcon from '@mui/icons-material/Error';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AnimateButton from 'ui-component/extended/AnimateButton';
import TextField from '@mui/material/TextField';
import 'react-toggle/style.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import Paper from '@mui/material/Paper';
import { TablePaginationActions } from '../../../../../components/Pagination/TablePaginationActions';
import { useDispatch, useSelector } from 'react-redux';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import PersonAdd from '@mui/icons-material/PersonAdd';
import FilterIcon from '@mui/icons-material/FilterAlt';
import { Link } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useNavigate } from 'react-router-dom';
import ReactLoading from 'react-loading';
import ViewIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { MENU_OPEN } from 'store/actions';
import ModalFilter from 'components/Modal/ModalFilter';
import { getStaffs } from 'services/staffs';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#c0c0c0',
        color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));
export default function GridStaffs({ contractSelected, partnerSelected, openModalFilter, setOpenModalFilter }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const page = useSelector((state) => state.staffs.page);
    const nameStaff = useSelector((state) => state.staffs.nameStaff);
    const rowsPerPage = useSelector((state) => state.staffs.rowsPerPage);
    const user = useSelector((state) => state.auth.user);
    const [staffs, setStaffs] = useState([]);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleCloseModalFilter = () => setOpenModalFilter(false);
    const withLink = (to, children) => <Link to={to}>{children}</Link>;

    const handleChangePage = (event, newPage) => {
        dispatch({ type: 'SET_PAGE_STAFF', payload: newPage });
    };
    const handleChangeRowsPerPage = (event) => {
        dispatch({ type: 'SET_ROWS_PER_PAGE_STAFF', payload: parseInt(event.target.value, 10) });
        dispatch({ type: 'SET_PAGE_STAFF', payload: 0 });
    };

    function getAllStaffs(nameStaffAttr) {
        let nameStaffsFilter = nameStaffAttr === '' ? nameStaffAttr : nameStaff;
        getStaffs(page + 1, rowsPerPage, nameStaffsFilter, contractSelected, partnerSelected)
            .then((resp) => setStaffs(resp.data))
            .finally(() => setLoading(false));
    }

    const actions = [
        { icon: withLink('/novo_efetivo', <PersonAdd />), name: 'Novo Efetivo' },
        { icon: withLink('#/', <FilterIcon />), name: 'Filtros', operation: 'filtros' }
    ];

    function formatarCPF(numero) {
        let cpf = numero.toString();
        return cpf.substring(0, 3) + '.' + cpf.substring(3, 6) + '.' + cpf.substring(6, 9) + '-' + cpf.substring(9);
    }

    useEffect(() => {
        if (contractSelected !== '' || partnerSelected !== '') {
            setLoading(true);
            getAllStaffs();
        }
    }, [page, rowsPerPage, contractSelected, partnerSelected]);

    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === 'efetivos');
        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: 'efetivos' });
        }
    }, []);

    return (
        <>
            <ModalFilter
                open={openModalFilter}
                title="Filtros"
                handleClose={handleCloseModalFilter}
                content={
                    <>
                        <Grid container spacing={matchDownSM ? 0 : 2}>
                            <Grid item xs={12} sm={12} sx={{ marginTop: 3 }}>
                                <TextField
                                    fullWidth
                                    id="outlined-nameStaff"
                                    type="text"
                                    label="Nome"
                                    value={nameStaff}
                                    onChange={(e) => dispatch({ type: 'SET_NAMESTAFF_STAFF_FILTER', payload: e.target.value })}
                                    name="nameStaff"
                                />
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" justifyContent="center" sx={{ mt: 3 }}>
                            <Grid item>
                                <Box sx={{ mt: 2, mr: 3 }}>
                                    <AnimateButton>
                                        <Button
                                            disableElevation
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            color="error"
                                            onClick={(e) => [
                                                dispatch({ type: 'SET_CLEAR_STAFF_FILTER' }),
                                                setOpenModalFilter(false),
                                                getAllStaffs('', '')
                                            ]}
                                        >
                                            Limpar Busca
                                        </Button>
                                    </AnimateButton>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={{ mt: 2, mr: 3 }}>
                                    <AnimateButton>
                                        <Button
                                            disableElevation
                                            fullWidth
                                            size="large"
                                            type="button"
                                            variant="contained"
                                            color="primary"
                                            onClick={(e) => [getAllStaffs(), setOpenModalFilter(false)]}
                                        >
                                            Buscar
                                        </Button>
                                    </AnimateButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </>
                }
            />
            {loading ? (
                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <ReactLoading type="spin" color="#008B8B" height={100} width={100} />
                </div>
            ) : staffs?.data?.length > 0 ? (
                <TableContainer sx={{ mt: 2, height: '100%' }} component={Paper}>
                    {error || success ? (
                        <Snackbar open={true} autoHideDuration={6000}>
                            <Alert
                                severity={error ? 'error' : success ? 'success' : ''}
                                sx={{
                                    width: '100%',
                                    backgroundColor: error ? 'red' : success ? 'green' : 'orange',
                                    color: '#FFF'
                                }}
                            >
                                {error ? error : success ? success : ''}
                            </Alert>
                        </Snackbar>
                    ) : (
                        ''
                    )}
                    <Table sx={{ minWidth: 700 }} aria-label="customized table" style={{ borderRadius: '10px' }}>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell style={{ backgroundColor: '#DCDCDC', color: 'black', borderRadius: '10px 0px 0px 0px' }}>
                                    Nome
                                </StyledTableCell>
                                <StyledTableCell style={{ backgroundColor: '#DCDCDC', color: 'black' }}>CPF</StyledTableCell>
                                <StyledTableCell style={{ backgroundColor: '#DCDCDC', color: 'black', borderRadius: '0px 10px 0px 0px' }}>
                                    Ações
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {staffs?.data?.map((row) => (
                                <StyledTableRow key={row.id}>
                                    <StyledTableCell style={{ color: 'black' }}>{row.nome}</StyledTableCell>
                                    <StyledTableCell style={{ color: 'black' }}>{formatarCPF(row.cpf)}</StyledTableCell>
                                    <div style={{ display: 'flex', marginTop: '9px' }}>
                                        <Tooltip title="Visualizar">
                                            <IconButton onClick={() => navigate({ pathname: `/efetivo/${row.id}/view` })}>
                                                <ViewIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Editar">
                                            <IconButton
                                                onClick={() => navigate({ pathname: `/efetivo/${row.id}/edit` })}
                                                disabled={user?.perfil_id === 3}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
                                    colSpan={6}
                                    count={staffs.total}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            'aria-label': 'Registros por Página'
                                        },
                                        native: true
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            ) : (contractSelected === null || contractSelected === '') && (partnerSelected === null || partnerSelected === '') ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <h3 style={{ textAlign: 'center' }}>Selecione uma empresa/contrato</h3>
                    <ErrorIcon color="warning" />
                </div>
            ) : (
                <h3 style={{ textAlign: 'center', marginTop: '100px' }}>Nenhum dado encontrado</h3>
            )}

            {partnerSelected !== null && partnerSelected !== '' && (
                <SpeedDial
                    direction="left"
                    ariaLabel="SpeedDial basic example"
                    sx={{
                        position: 'absolute',
                        top: '90%',
                        position: 'fixed',
                        right: 50,
                        zIndex: theme.zIndex.speedDial
                    }}
                    icon={<SpeedDialIcon />}
                >
                    {actions.map((action) => (
                        <SpeedDialAction
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                            onClick={(e) => (action.operation === 'filtros' ? [e.stopPropagation(), setOpenModalFilter(true)] : '')}
                        />
                    ))}
                </SpeedDial>
            )}
        </>
    );
}

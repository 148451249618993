import { useState, useEffect } from 'react';
import {
    Checkbox,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    MenuItem,
    Select,
    Typography
} from '@mui/material';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { DivFlex } from './styles';
import Toggle from 'react-toggle';
import { getTypeDocuments } from 'services/contracts';
import { getGroupDocuments } from 'services/groupDocuments';
import { indigo } from '@mui/material/colors';

const ContractsDocuments = ({ contracts, contractsSelected, setContractsSelected, contractsDocuments, setContractsDocuments, staff }) => {
    const [contractSelected, setContractSelected] = useState(null);

    const [documentGroups, setDocumentGroups] = useState([]);
    const [documentGroupSelected, setDocumentGroupSelected] = useState(null);

    const [documentTypes, setDocumentTypes] = useState([]);

    const getGroupsDocuments = () => {
        getGroupDocuments(1, '').then((resp) => {
            setDocumentGroups(resp.data);
        });
    };

    const getTypesDocuments = () => {
        getTypeDocuments(documentGroupSelected).then((resp) => {
            setDocumentTypes(resp.data);
        });
    };

    const handleCheckContract = (value) => {
        const currentIndex = contractsSelected.indexOf(value);
        const newChecked = [...contractsSelected];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setContractsSelected(newChecked);
    };

    const handleToggle = (docId, isChecked) => {
        if (contractSelected) {
            if (isChecked) {
                const contractDocuments = { ...contractsDocuments?.find((doc) => doc.contractId === contractSelected.id) };
                setContractsDocuments([
                    ...contractsDocuments?.filter((doc) => doc.contractId !== contractSelected.id),
                    {
                        contractId: contractSelected.id,
                        documents: contractDocuments.documents ? [...contractDocuments.documents, docId] : [docId]
                    }
                ]);
            } else {
                const contractDocuments = { ...contractsDocuments?.find((doc) => doc.contractId === contractSelected.id) };
                setContractsDocuments([
                    ...contractsDocuments?.filter((doc) => doc.contractId !== contractSelected.id),
                    {
                        contractId: contractSelected.id,
                        documents: contractDocuments.documents?.filter((doc) => doc !== docId)
                    }
                ]);
            }
        }
    };

    useEffect(() => {
        getGroupsDocuments();
    }, []);

    useEffect(() => {
        if (documentGroupSelected) getTypesDocuments();
    }, [documentGroupSelected]);

    return (
        <>
            <Typography variant="h3" sx={{ mt: 3 }}>
                Contratos
            </Typography>
            <Grid container display="flex" alignItems="flex-start" justifyContent="flex-start" spacing={2}>
                <Grid item xs={12} sm={4}>
                    <List sx={{ width: '100%' }} color="secondary">
                        {contracts?.data?.map((contract, index) => {
                            const labelId = `checkbox-list-secondary-label-${contract.id}`;
                            return (
                                <ListItem
                                    key={index}
                                    sx={{ padding: 0, mb: 1, color: contractSelected?.id == contract.id ? 'white' : '#212121' }}
                                    secondaryAction={<OpenInNewOutlinedIcon />}
                                >
                                    <Checkbox
                                        edge="end"
                                        onChange={() => handleCheckContract(contract.id)}
                                        checked={contractsSelected.indexOf(contract.id) !== -1}
                                        inputProps={{ 'aria-labelledby': labelId }}
                                        sx={{ mr: 1 }}
                                    />
                                    <ListItemButton
                                        sx={{
                                            width: '100%',
                                            border: '1px solid #ccc',
                                            borderRadius: '10px',
                                            backgroundColor: contractSelected?.id == contract.id ? indigo[500] : 'white',
                                            '&:hover': {
                                                backgroundColor: contractSelected?.id == contract.id ? indigo[500] : 'white'
                                            }
                                        }}
                                        onClick={() => setContractSelected(contract)}
                                    >
                                        <ListItemText
                                            id={labelId}
                                            primary={contract.numero}
                                            sx={{ color: contractSelected?.id == contract.id ? 'white' : '#212121' }}
                                            disableTypography
                                        />
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}
                    </List>
                </Grid>
                {(contractSelected && (
                    <Grid item xs={12} sm={8}>
                        <Typography variant="h4">Contrato selecionado: {contractSelected?.numero}</Typography>
                        <Divider sx={{ mt: 2 }} />
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Documentação</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={documentGroupSelected}
                                label="Documentação"
                                onChange={(event) => setDocumentGroupSelected(event.target.value)}
                            >
                                {documentGroups.map((group) => (
                                    <MenuItem key={group.id} value={group.id}>
                                        {group.nome}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Divider sx={{ mt: 2 }} />
                        {documentTypes.map((type) => (
                            <Grid container key={type.id}>
                                <Grid item xs={12} sm={12}>
                                    <DivFlex>
                                        <div style={{ display: 'inline-block' }}>
                                            <Toggle
                                                disabled={contractSelected === null || !contractsSelected.includes(contractSelected.id)}
                                                checked={
                                                    !contractsSelected?.includes(contractSelected?.id)
                                                        ? false
                                                        : contractsDocuments
                                                              ?.find((doc) => doc.contractId == contractSelected?.id)
                                                              ?.documents?.includes(type.id)
                                                }
                                                onChange={(e) => handleToggle(type.id, e.target.checked)}
                                            />
                                        </div>
                                        <div style={{ display: 'inline-block', marginLeft: 10 }}>
                                            <h3>{type?.nome}</h3>
                                        </div>
                                    </DivFlex>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                )) || (
                    <Grid item xs={12} sm={8}>
                        <Typography variant="h4">Selecione um contrato</Typography>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default ContractsDocuments;

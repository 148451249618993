import { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
// material-ui
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import {
    Box,
    Button,
    FormHelperText,
    Grid,
    TextField,
    useMediaQuery,
    Alert,
    Snackbar,
    MenuItem,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Switch,
    InputLabel,
    Select
} from '@mui/material';
import Checkbox from '@material-ui/core/Checkbox';

// third party
import * as Yup from 'yup';
import { useFormikContext, Formik, useFormik } from 'formik';
import InputMask from 'react-input-mask';
// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useDispatch, useSelector } from 'react-redux';
// assets
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import Loading from 'components/Loading/Loading';
import { getPartnerPerId } from 'services/partners';
import { postContract, updateContract, getContracts } from 'services/contracts';
import { postStaff, updateStaff, getStaffPerId } from 'services/staffs';
import Toggle from 'react-toggle';
import { FormLabelDiv, CheckBoxStyled } from './styles';
import { CheckboxStyled } from 'components/BI/filterList/styles';
import ContractsDocuments from './ContractsDocuments';
// ===========================|| FIREBASE - REGISTER ||=========================== //

const FormStaff = ({ ...others }) => {
    const params = useParams();
    const themeButton = createTheme({
        status: {
            danger: '#e53e3e'
        },
        palette: {
            primary: {
                main: '#0971f1',
                darker: '#053e85'
            },
            neutral: {
                main: '#64748B',
                contrastText: '#fff'
            }
        }
    });

    const theme = useTheme();
    const navigate = useNavigate();
    const user = useSelector((state) => state.auth.user);
    const scriptedRef = useScriptRef();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [loading, setLoading] = useState(false);
    const partnerSelectId = useSelector((state) => state.staffs.partnerSelect);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [partner, setPartner] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [staff, setStaff] = useState([]);
    const [errorValidate, setErrorValidate] = useState({});
    const isDisabled = params.action === 'view' || user?.perfil_id === 3 ? true : false;
    const [selectedContracts, setSelectedContracts] = useState([]);
    const [contractsDocuments, setContractsDocuments] = useState([]);

    function getPartner() {
        getPartnerPerId(partnerSelectId)
            .then((resp) => {
                setPartner(resp.data.data);
            })
            .finally(() => getAllContracts());
    }

    function getAllContracts() {
        getContracts('', '', '', partnerSelectId, '').then((resp) => setContracts(resp.data));
    }

    function viewPerId() {
        params.action &&
            getStaffPerId(params.id)
                .then((resp) => {
                    setStaff(resp.data?.data);
                    setSelectedContracts(resp.data?.data?.contrato_id);
                    setContractsDocuments(
                        resp.data?.data?.documentos?.map((docs) => {
                            return { contractId: docs.contrato_id, documents: docs.documentos };
                        })
                    );
                })
                .catch((e) => {
                    console.log(e);

                    setSuccess('');
                    setError(e.response.data.error);
                    setTimeout(() => {
                        setError('');
                    }, 4000);
                });
    }

    const handleCheckboxChange = (event, contractNumero) => {
        setSelectedContracts((prevContracts) => {
            if (event.target.checked) {
                return [...prevContracts, contractNumero];
            } else {
                return prevContracts.filter((num) => num !== contractNumero);
            }
        });
    };
    console.log('partnerSelectId', partnerSelectId);
    if (!partnerSelectId && params.action !== 'view') {
        navigate('/efetivos');
    }

    useEffect(() => {
        getPartner();
    }, []);

    useEffect(() => {
        viewPerId();
    }, [params.id]);

    return (
        <>
            <Formik
                initialValues={{
                    nome: staff?.nome || '',
                    cpf: staff?.cpf || '',
                    ativo: staff?.ativo || '',
                    submit: null
                }}
                enableReinitialize
                validationSchema={Yup.object().shape({
                    nome: Yup.string()
                        .required('Nome do efetivo é obrigatório')
                        .max(100, 'Nome do efetivo deve ter no maximo 100 caracteres'),
                    cpf: Yup.string().required('CPF do efetivo é obrigatório').max(14, 'CPF do efetivo deve ter no maximo 14 caracteres')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm, setFieldValue }) => {
                    try {
                        if (scriptedRef.current) {
                            setStatus({ success: true });
                            setSubmitting(false);
                        }
                        setLoading(true);
                        if (params.action === 'edit') {
                            values.cpf = values.cpf.replace(/\D/g, '');
                            if (selectedContracts.length === 0) {
                                setErrorValidate('Selecione pelo menos um contrato');
                                setLoading(false);
                                return setError('Selecione pelo menos um contrato');
                            }
                            const data = {
                                ...values,
                                contratos: selectedContracts.map((contract) => {
                                    return {
                                        contrato_id: contract,
                                        documentos: contractsDocuments.find((doc) => doc.contractId == contract)?.documents
                                    };
                                })
                            };
                            updateStaff(params.id, data)
                                .then((resp) => {
                                    setError('');
                                    setLoading(false);
                                    viewPerId();
                                    setSuccess(resp.data.success);
                                    setTimeout(() => {
                                        setSuccess('');
                                    }, 3000);
                                })
                                .catch((e) => {
                                    console.error(e);
                                    setLoading(false);
                                    setSuccess('');
                                    setErrorValidate(e.response?.data?.errorValidate || 'Erro ao atualizar o efetivo');
                                    setError(e.response?.data?.error || 'Erro ao atualizar o efetivo');
                                    setTimeout(() => {
                                        setError('');
                                    }, 3000);
                                });
                        } else {
                            if (selectedContracts.length === 0) {
                                setErrorValidate('Selecione pelo menos um contrato');
                                setLoading(false);
                                return setError('Selecione pelo menos um contrato');
                            }
                            values.cpf = values.cpf.replace(/\D/g, '');
                            const data = {
                                ...values,
                                contratos: selectedContracts.map((contract) => {
                                    return {
                                        contrato_id: contract,
                                        documentos: contractsDocuments.find((doc) => doc.contractId == contract)?.documents
                                    };
                                })
                            };
                            postStaff(data)
                                .then((resp) => {
                                    setError('');
                                    setLoading(false);
                                    setSuccess(resp.data.success);
                                    setTimeout(() => {
                                        resetForm();
                                        setSuccess('');
                                    }, 2000);
                                })
                                .catch((e) => {
                                    console.log(e);
                                    setLoading(false);
                                    setSuccess('');
                                    if (e.response) {
                                        const { errorValidate, errors, error } = e.response.data;

                                        if (errorValidate) {
                                            setErrorValidate(errorValidate);
                                        }

                                        if (errors) {
                                            const errorMessages = Object.values(errors).flat();
                                            setError(errorMessages[0]);
                                            for (const campo in errors) {
                                                if (errors.hasOwnProperty(campo)) {
                                                    const mensagensErroCampo = errors[campo];
                                                    console.log(`${campo}: ${mensagensErroCampo.join(', ')}`);
                                                }
                                            }
                                        }

                                        console.log('Status da resposta de erro:', e.response.status);
                                    } else if (e.request) {
                                        console.log('Requisição feita, mas sem resposta');
                                        setError('Erro: Sem resposta do servidor');
                                    } else {
                                        console.log('Erro ao processar a requisição:', e.message);
                                        setError('Ocorreu um erro ao processar a solicitação.');
                                    }
                                });
                        }
                    } catch (err) {
                        console.error(err);
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                    <>
                        {loading && (
                            <Grid container alignItems="center" justifyContent="center">
                                <MainCard style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Loading color="#015641" type="cubes" />
                                </MainCard>
                            </Grid>
                        )}
                        <MainCard spacing={gridSpacing} style={{ margin: 25 }}>
                            <Grid xs={12} md={12} sm={12} container>
                                <h1
                                    style={{
                                        font: 'normal normal bold 35px/44px',
                                        letterSpacing: '0px',
                                        opacity: 1
                                    }}
                                >
                                    Cadastro de efetivos
                                </h1>
                                <hr style={{ width: '100%', marginTop: 0 }}></hr>
                            </Grid>
                            {error || alert ? (
                                <Snackbar open={true} autoHideDuration={6000}>
                                    <Alert
                                        severity={error ? 'error' : success ? 'success' : ''}
                                        sx={{
                                            width: '100%',
                                            backgroundColor: error ? 'red' : success ? 'green' : 'orange',
                                            color: '#FFF'
                                        }}
                                    >
                                        {error ? error : success ? success : ''}
                                    </Alert>
                                </Snackbar>
                            ) : (
                                ''
                            )}
                            <div style={{ display: loading ? 'none' : 'block' }}>
                                <form noValidate onSubmit={handleSubmit} {...others}>
                                    {partnerSelectId && (
                                        <Grid container spacing={matchDownSM ? 0 : 2}>
                                            <Grid item xs={12} sm={12} sx={{ marginTop: 1 }}>
                                                <div
                                                    style={{
                                                        textAlign: 'center',
                                                        backgroundColor: '#f5f5f5',
                                                        padding: 10,
                                                        borderRadius: 10,
                                                        width: '100%'
                                                    }}
                                                >
                                                    <h2>{partner.nome_fantasia}</h2>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    )}
                                    <Grid container spacing={{ xs: 0, sm: 2 }}>
                                        <Grid item xs={12} sm={6} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.nome && errors.nome) || Boolean(errorValidate.nome)}
                                                id="outlined-nome"
                                                type="text"
                                                label="Nome"
                                                value={values?.nome}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="nome"
                                                disabled={isDisabled}
                                                helperText={
                                                    touched.nome && errors.nome ? errors.nome : errorValidate.nome ? errorValidate.nome : ''
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} sx={{ marginTop: 3 }}>
                                            <InputMask
                                                mask="999.999.999-99"
                                                value={values?.cpf}
                                                disabled={isDisabled}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            >
                                                {(inputProps) => (
                                                    <TextField
                                                        {...inputProps}
                                                        disabled={isDisabled}
                                                        fullWidth
                                                        error={Boolean(touched.cpf && errors.cpf) || Boolean(errorValidate.cpf)}
                                                        id="outlined-cpf"
                                                        type="text"
                                                        label="CPF"
                                                        name="cpf"
                                                        helperText={
                                                            touched.cpf && errors.cpf
                                                                ? errors.cpf
                                                                : errorValidate.cpf
                                                                ? errorValidate.cpf
                                                                : ''
                                                        }
                                                    />
                                                )}
                                            </InputMask>
                                        </Grid>
                                        {/* <Grid item xs={1} sm={1} sx={{ marginTop: 5 }}>
                                            <Toggle
                                                value={values?.ativo}
                                                onChange={(checked) => setFieldValue('ativo', checked)}
                                                disabled={isDisabled}
                                            />
                                        </Grid> */}
                                    </Grid>
                                    <ContractsDocuments
                                        contracts={contracts}
                                        contractsSelected={selectedContracts}
                                        setContractsSelected={setSelectedContracts}
                                        contractsDocuments={contractsDocuments}
                                        setContractsDocuments={(docs) => setContractsDocuments(docs)}
                                        staff={staff}
                                    />
                                    <Grid container alignItems="end" justifyContent="end" sx={{ mt: 3 }}>
                                        <Grid item>
                                            <Box sx={{ mt: 2, mr: 3 }}>
                                                <ThemeProvider theme={themeButton}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            disabled={isSubmitting}
                                                            fullWidth
                                                            size="large"
                                                            type="button"
                                                            variant="contained"
                                                            color="neutral"
                                                            onClick={() => navigate(-1)}
                                                        >
                                                            Voltar
                                                        </Button>
                                                    </AnimateButton>
                                                </ThemeProvider>
                                            </Box>
                                        </Grid>
                                        {params.action === 'view' ? (
                                            <Grid item>
                                                <Box sx={{ mt: 2, mr: 3 }}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            // disabled={isSubmitting}
                                                            component={Link}
                                                            to={`/efetivo/${params.id}/edit`}
                                                            fullWidth
                                                            size="large"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Editar
                                                        </Button>
                                                    </AnimateButton>
                                                </Box>
                                            </Grid>
                                        ) : (
                                            <Grid item>
                                                <Box sx={{ mt: 2, mr: 3 }}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            disabled={isSubmitting}
                                                            fullWidth
                                                            size="large"
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Salvar
                                                        </Button>
                                                    </AnimateButton>
                                                </Box>
                                            </Grid>
                                        )}
                                    </Grid>
                                    {errors.submit && (
                                        <Box sx={{ mt: 3 }}>
                                            <FormHelperText error>{errors.submit}</FormHelperText>
                                        </Box>
                                    )}
                                </form>
                            </div>
                        </MainCard>
                    </>
                )}
            </Formik>
        </>
    );
};

export default FormStaff;

import styled from 'styled-components';

import { FormControlLabel } from '@mui/material';
import { CheckBox } from '@mui/icons-material';
export const FormLabelDiv = styled(FormControlLabel)`
    margin-left: 5px;
`;
export const CheckBoxStyled = styled(CheckBox)``;

export const DivFlex = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1rem;
    background-color: #f5f5f5;
    border-radius: 0.5rem;
`;

import { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
// material-ui
import { useTheme, createTheme, ThemeProvider, TextareaAutosize } from '@mui/material/styles';
import {
    Box,
    Button,
    FormHelperText,
    Grid,
    TextField,
    useMediaQuery,
    Alert,
    Snackbar,
    MenuItem,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Switch,
    InputLabel,
    Select
} from '@mui/material';
import { DivFlex } from './styles';
// third party
import * as Yup from 'yup';
import { useFormikContext, Formik } from 'formik';
import InputMask from 'react-input-mask';
// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';

// assets
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import Loading from 'components/Loading/Loading';
import { getUserPerId, postUser, updateUser } from 'services/users';

// ===========================|| FIREBASE - REGISTER ||=========================== //

const User = ({ ...others }) => {
    const params = useParams();
    const themeButton = createTheme({
        status: {
            danger: '#e53e3e'
        },
        palette: {
            primary: {
                main: '#0971f1',
                darker: '#053e85'
            },
            neutral: {
                main: '#64748B',
                contrastText: '#fff'
            }
        }
    });

    const perfis = [
        {
            id: 1,
            name: 'Administrador'
        },
        {
            id: 2,
            name: 'Parceiro'
        },
        {
            id: 3,
            name: 'Áreas Internas'
        }
    ];

    const theme = useTheme();
    const navigate = useNavigate();

    const scriptedRef = useScriptRef();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [user, setUser] = useState([]);
    const [errorValidate, setErrorValidate] = useState({});
    const isDisabled = params.action === 'view' ? true : false;

    function viewPerId() {
        params.action &&
            getUserPerId(params.id)
                .then((resp) => {
                    setUser(resp.data?.data);
                })
                .catch((e) => {
                    console.log(e);

                    setSuccess('');
                    setError(e.response.data.error);
                    setTimeout(() => {
                        setError('');
                    }, 4000);
                });
    }

    useEffect(() => {
        viewPerId();
    }, [params.id]);

    return (
        <>
            <Formik
                initialValues={{
                    name: user?.name || '',
                    email: user?.email || '',
                    perfil_id: user?.perfil_id || '',
                    submit: null
                }}
                enableReinitialize
                validationSchema={Yup.object().shape({
                    perfil_id: Yup.number().required('Perfil é obrigatório'),
                    name: Yup.string()
                        .required('Nome é obrigatório')
                        .max(100, 'Nome deve ter no maximo 100 caracteres')
                        .min(3, 'Nome deve ter no minimo 3 caracteres'),
                    email: Yup.string()
                        .required('Email é obrigatório')
                        .email('Email inválido')
                        .max(100, 'Email deve ter no maximo 100 caracteres')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm, setFieldValue }) => {
                    try {
                        if (scriptedRef.current) {
                            setStatus({ success: true });
                            setSubmitting(false);
                        }
                        setLoading(true);
                        if (params.action === 'edit') {
                            updateUser(params.id, values)
                                .then((resp) => {
                                    setError('');
                                    setLoading(false);
                                    viewPerId();
                                    setSuccess(resp.data.success);
                                    setTimeout(() => {
                                        setSuccess('');
                                    }, 3000);
                                })
                                .catch((e) => {
                                    setLoading(false);
                                    setSuccess('');
                                    setErrorValidate(e.response.data.errorValidate);
                                    setError(e.response.data.error);
                                    setTimeout(() => {
                                        resetForm();
                                        setError('');
                                    }, 3000);
                                });
                        } else {
                            values.ativo = 1;
                            postUser(values)
                                .then((resp) => {
                                    setError('');
                                    setLoading(false);
                                    setSuccess(resp.data.success);
                                    setTimeout(() => {
                                        resetForm();
                                        setSuccess('');
                                    }, 2000);
                                })
                                .catch((e) => {
                                    console.log(e);
                                    setLoading(false);
                                    setSuccess('');

                                    if (e.response) {
                                        const { errorValidate, errors, error } = e.response.data;

                                        if (errorValidate) {
                                            setErrorValidate(errorValidate);
                                        }

                                        if (errors) {
                                            const errorMessages = Object.values(errors).flat();
                                            setError(errorMessages[0]);
                                            for (const campo in errors) {
                                                if (errors.hasOwnProperty(campo)) {
                                                    const mensagensErroCampo = errors[campo];
                                                    console.log(`${campo}: ${mensagensErroCampo.join(', ')}`);
                                                }
                                            }
                                        }

                                        console.log('Status da resposta de erro:', e.response.status);
                                    } else if (e.request) {
                                        console.log('Requisição feita, mas sem resposta');
                                        setError('Erro: Sem resposta do servidor');
                                    } else {
                                        console.log('Erro ao processar a requisição:', e.message);
                                        setError('Ocorreu um erro ao processar a solicitação.');
                                    }
                                });
                        }
                    } catch (err) {
                        console.error(err);
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                    <>
                        {console.log(values)}
                        {loading && (
                            <Grid container alignItems="center" justifyContent="center">
                                <MainCard style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Loading color="#015641" type="cubes" />
                                </MainCard>
                            </Grid>
                        )}
                        <MainCard spacing={gridSpacing} style={{ margin: 25 }}>
                            <Grid xs={12} md={12} sm={12} container>
                                {params?.action === 'view' ? (
                                    <h1
                                        style={{
                                            font: 'normal normal bold 35px/44px',
                                            letterSpacing: '0px',
                                            opacity: 1
                                        }}
                                    >
                                        Visualizar Usuário
                                    </h1>
                                ) : params?.action === 'edit' ? (
                                    <h1
                                        style={{
                                            font: 'normal normal bold 35px/44px',
                                            letterSpacing: '0px',
                                            opacity: 1
                                        }}
                                    >
                                        Editar Usuário
                                    </h1>
                                ) : (
                                    <h1
                                        style={{
                                            font: 'normal normal bold 35px/44px',
                                            letterSpacing: '0px',
                                            opacity: 1
                                        }}
                                    >
                                        Novo Usuário
                                    </h1>
                                )}

                                <hr style={{ width: '100%', marginTop: 0 }}></hr>
                            </Grid>
                            {error || alert ? (
                                <Snackbar open={true} autoHideDuration={6000}>
                                    <Alert
                                        severity={error ? 'error' : success ? 'success' : ''}
                                        sx={{
                                            width: '100%',
                                            backgroundColor: error ? 'red' : success ? 'green' : 'orange',
                                            color: '#FFF'
                                        }}
                                    >
                                        {error ? error : success ? success : ''}
                                    </Alert>
                                </Snackbar>
                            ) : (
                                ''
                            )}
                            <div style={{ display: loading ? 'none' : 'block' }}>
                                <form noValidate onSubmit={handleSubmit} {...others}>
                                    <Grid container spacing={matchDownSM ? 0 : 2}>
                                        <Grid item xs={4} sm={4} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.name && errors.name) || Boolean(errorValidate.name)}
                                                id="outlined-nome-completo"
                                                type="text"
                                                label="Nome completo"
                                                value={values?.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="name"
                                                disabled={isDisabled}
                                                helperText={
                                                    touched.name && errors.name ? errors.name : errorValidate.name ? errorValidate.name : ''
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={4} sm={4} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.email && errors.email) || Boolean(errorValidate.email)}
                                                id="outlined-email"
                                                type="text"
                                                label="E-mail"
                                                value={values?.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="email"
                                                disabled={isDisabled}
                                                helperText={
                                                    touched.email && errors.email
                                                        ? errors.email
                                                        : errorValidate.email
                                                        ? errorValidate.email
                                                        : ''
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={4} sm={4} sx={{ marginTop: 3 }}>
                                            <FormControl
                                                fullWidth
                                                variant="outlined"
                                                error={Boolean(touched.perfil_id && errors.perfil_id) || Boolean(errorValidate.perfil_id)}
                                            >
                                                <InputLabel id="perfilSelectLabel">Selecione um perfil</InputLabel>
                                                <Select
                                                    labelId="perfilSelectLabel"
                                                    id="perfil_id"
                                                    name="perfil_id"
                                                    value={values?.perfil_id}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    disabled={isDisabled}
                                                    label="Perfil"
                                                >
                                                    <MenuItem value="">
                                                        <em>Selecione...</em>
                                                    </MenuItem>
                                                    {perfis?.map((perfil, index) => (
                                                        <MenuItem key={index} value={perfil.id}>
                                                            {perfil?.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {(touched.perfil_id && errors.perfil_id) || errorValidate.perfil_id ? (
                                                    <FormHelperText>
                                                        {touched.perfil_id && errors.perfil_id
                                                            ? errors.perfil_id
                                                            : errorValidate.perfil_id
                                                            ? errorValidate.perfil_id
                                                            : ''}
                                                    </FormHelperText>
                                                ) : null}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container alignItems="end" justifyContent="end" sx={{ mt: 3 }}>
                                        <Grid item>
                                            <Box sx={{ mt: 2, mr: 3 }}>
                                                <ThemeProvider theme={themeButton}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            disabled={isSubmitting}
                                                            fullWidth
                                                            size="large"
                                                            type="button"
                                                            variant="contained"
                                                            color="neutral"
                                                            onClick={() => navigate(-1)}
                                                        >
                                                            Voltar
                                                        </Button>
                                                    </AnimateButton>
                                                </ThemeProvider>
                                            </Box>
                                        </Grid>
                                        {params.action === 'view' ? (
                                            <Grid item>
                                                <Box sx={{ mt: 2, mr: 3 }}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            // disabled={isSubmitting}
                                                            component={Link}
                                                            to={`/usuario/${params.id}/edit`}
                                                            fullWidth
                                                            size="large"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Editar
                                                        </Button>
                                                    </AnimateButton>
                                                </Box>
                                            </Grid>
                                        ) : (
                                            <Grid item>
                                                <Box sx={{ mt: 2, mr: 3 }}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            disabled={isSubmitting}
                                                            fullWidth
                                                            size="large"
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Salvar
                                                        </Button>
                                                    </AnimateButton>
                                                </Box>
                                            </Grid>
                                        )}
                                    </Grid>
                                    {errors.submit && (
                                        <Box sx={{ mt: 3 }}>
                                            <FormHelperText error>{errors.submit}</FormHelperText>
                                        </Box>
                                    )}
                                </form>
                            </div>
                        </MainCard>
                    </>
                )}
            </Formik>
        </>
    );
};

export default User;

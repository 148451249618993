import styled from 'styled-components';

import { Button, Grid, InputLabel, css } from '@mui/material';

export const ButtonsGroupStyled = styled.div`
    width: 100%;
`;

export const ButtonStyled = styled(Button)`
    padding: 0.5rem;
    border-radius: 0.2rem;
`;

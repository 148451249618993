// assets
import {
    IconHome,
    IconUser,
    IconSettings,
    IconBuildingCommunity,
    IconChartLine,
    IconChartBar,
    IconLicense,
    IconMessages,
    IconNews,
    IconListCheck,
    IconBrandInstagram,
    IconMessageCircle,
    IconLogout,
    IconTractor,
    IconWindmill,
    IconBoxPadding,
    IconFriends,
    IconExchange
} from '@tabler/icons';

// constant
const icons = {
    IconHome,
    IconUser,
    IconSettings,
    IconChartLine,
    IconChartBar,
    IconLicense,
    IconBuildingCommunity,
    IconMessages,
    IconNews,
    IconListCheck,
    IconBrandInstagram,
    IconMessageCircle,
    IconLogout,
    IconTractor,
    IconWindmill,
    IconBoxPadding,
    IconFriends,
    IconExchange
};

const utilities = {
    id: 'utilities',
    type: 'group',
    titleName: true,
    children: [
        {
            id: 'index',
            title: 'Home',
            type: 'item',
            url: '/index',
            icon: icons.IconHome,
            breadcrumbs: false,
            color: 'success',
            variant: 'success'
        },
        {
            id: 'usuarios',
            title: 'Usuários',
            type: 'item',
            url: '/usuarios',
            icon: icons.IconUser,
            breadcrumbs: false,
            color: 'success',
            variant: 'success'
        },
        {
            id: 'parceiros',
            title: 'Parceiros',
            type: 'item',
            url: '/parceiros',
            icon: icons.IconFriends,
            breadcrumbs: false,
            color: 'success',
            variant: 'success'
        },
        {
            id: 'contratos',
            title: 'Contratos',
            type: 'item',
            url: '/contratos',
            icon: icons.IconLicense,
            breadcrumbs: false,
            color: 'success',
            variant: 'success'
        },
        {
            id: 'efetivos',
            title: 'Efetivos',
            type: 'item',
            url: '/efetivos',
            icon: icons.IconExchange,
            breadcrumbs: false,
            color: 'success',
            variant: 'success'
        }
    ]
};

export default utilities;

import { useSelector } from 'react-redux';
import administrationCenter from './administrationCenter';
import configurationCenter from './configurationCenter';
import registrationCenter from './registrationCenter';
import reportCenter from './reportCenter';
import utilities from './utilities';
import contractsGroup from './contractsGroup';
import staffsDocumentsGroup from './staffsDocumentsGroup';

// ==============================|| MENU ITEMS ||============================== //
const menuItems = {
    // items: [utilities, configurationCenter, administrationCenter]
    items: [utilities, contractsGroup, staffsDocumentsGroup]
};

export default menuItems;

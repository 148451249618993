import { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { getCompanies } from 'services/company';
import { useDispatch, useSelector } from 'react-redux';

const MenuSelect = () => {
    const dispatch = useDispatch();

    const [empresas, setEmpresas] = useState([]);
    const [empresaSelected, setEmpresaSelected] = useState(null);

    const { company } = useSelector((state) => state.company);

    useEffect(() => {
        dispatch({ type: 'SET_COMPANY_SELECTED', payload: empresas.find((empresa) => empresa.id === empresaSelected) || {} });
    }, [empresaSelected]);

    useEffect(() => {
        getCompanies().then((response) => {
            const { data } = response.data;
            setEmpresas(data);
        });
    }, []);

    useEffect(() => {
        if (!empresaSelected && empresas.length > 0) {
            setEmpresaSelected(empresas[0].id);
        }
    }, [empresas]);

    return (
        <>
            <FormControl fullWidth>
                <InputLabel id="empresaSelectLabel">Empresa</InputLabel>
                <Select
                    labelId="empresaSelectLabel"
                    id="empresa_id"
                    name="empresa_id"
                    value={empresaSelected}
                    onChange={(event) => {
                        setEmpresaSelected(event.target.value);
                    }}
                    label="Empresa"
                >
                    {empresas?.map((empresa, index) => (
                        <MenuItem key={index} value={empresa.id}>
                            {empresa?.nome}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
};

export default MenuSelect;

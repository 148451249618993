import { nanoid } from 'nanoid';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Modal, Box, Typography, Divider, LinearProgress } from '@mui/material';
import { DropZone } from './drop-zone';
import { FilesList } from './files-list';
import styles from './file-picker.module.css';
import { uploadFile } from 'services/documents';
import CloseIcon from '@mui/icons-material/Close';

const UploadDocumentFiles = ({
    openModal,
    handleCloseModal,
    accept,
    setSuccess,
    contractSelected,
    typeDocumentSelected,
    staffSelected,
    dateSelected,
    reloadDocuments
}) => {
    const [files, setFiles] = useState([]);
    const [progress, setProgress] = useState(0);
    const [uploadStarted, setUploadStarted] = useState(false);

    const handleClose = () => {
        handleCloseModal();
        setFiles([]);
    };

    // handler called when files are selected via the Dropzone component
    const handleOnChange = useCallback((files) => {
        let filesArray = Array.from(files);

        filesArray = filesArray.map((file) => ({
            id: nanoid(),
            file
        }));

        setFiles(filesArray);
        setProgress(0);
        setUploadStarted(false);
    }, []);

    // handle for removing files form the files list view
    const handleClearFile = useCallback((id) => {
        setFiles((prev) => prev.filter((file) => file.id !== id));
    }, []);

    // whether to show the progress bar or not
    const canShowProgress = useMemo(() => files.length > 0, [files.length]);

    // execute the upload operation
    const handleUpload = useCallback(async () => {
        try {
            setUploadStarted(true);

            const data = new FormData();

            data.append('contrato_id', contractSelected);
            data.append('tipo_documento_id', typeDocumentSelected?.id);

            if (staffSelected) {
                data.append('efetivo_id', staffSelected.id);
            }

            if (dateSelected) {
                data.append('periodo', dateSelected ? format(dateSelected, 'yyyy-MM') : '');
            }

            files.map((file) => {
                data.append('arquivos[]', file.file);
            });

            await uploadFile(data, {
                onUploadProgress: (progressEvent) => {
                    setUploadStarted(true);
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setProgress(percentCompleted);
                },
                headers: {
                    'Content-Type': `multipart/form-data;boundary=${data._boundary}`
                }
            });

            reloadDocuments();
            setUploadStarted(false);
            handleClose();

            setSuccess('Arquivos enviados com sucesso!');
            setTimeout(() => {
                setSuccess('');
            }, 3000);
        } catch (error) {
            console.log(error);
        }
    }, [files.length]);

    // set progress to zero when there are no files
    useEffect(() => {
        if (files.length < 1) {
            setProgress(0);
        }
    }, [files.length]);

    // set uploadStarted to false when the upload is complete
    useEffect(() => {
        if (progress === 100) {
            setUploadStarted(false);
        }
    }, [progress]);

    const uploadComplete = useMemo(() => progress === 100, [progress]);

    return (
        <Modal open={openModal} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    minWidth: 400,
                    maxWidth: 600,
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 2,
                    borderRadius: '20px'
                }}
            >
                <Typography id="modal-title" variant="h3" component="h2">
                    Anexar arquivos do(a) {typeDocumentSelected?.nome}
                    <CloseIcon style={{ float: 'right', cursor: 'pointer', color: 'gray' }} onClick={handleClose} />
                </Typography>
                <br />
                <Divider />

                <div className={styles.wrapper}>
                    {/* canvas */}
                    <div className={styles.canvas_wrapper}>
                        <DropZone onChange={handleOnChange} accept={accept} />
                    </div>

                    {/* files listing */}
                    {files.length ? (
                        <div className={styles.files_list_wrapper}>
                            <FilesList files={files} onClear={handleClearFile} uploadComplete={uploadComplete} />
                        </div>
                    ) : null}

                    {/* progress bar */}
                    {canShowProgress ? (
                        <div className={styles.files_list_progress_wrapper}>
                            <LinearProgress variant="determinate" color="success" value={progress} max={100} style={{ width: '100%' }} />
                        </div>
                    ) : null}

                    {/* upload button */}
                    {files.length ? (
                        <Button
                            onClick={handleUpload}
                            color="primary"
                            variant="contained"
                            sx={{ marginRight: '5px' }}
                            disabled={uploadStarted}
                        >
                            {`Enviar ${files.length} Arquivo(s)`}
                        </Button>
                    ) : null}
                </div>
            </Box>
        </Modal>
    );
};

export default UploadDocumentFiles;

import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import FirstAccess from './FirstAccess';
import config from 'config';
import { useSelector } from 'react-redux';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    // const logged = useSelector((state) => state.auth.loggedIn);
    // console.log('logged', logged);
    const route = [MainRoutes, AuthenticationRoutes];

    return useRoutes(route, config.basename);
}

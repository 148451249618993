import ReactDOM from 'react-dom';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// project imports
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import { store } from 'store';

// style + assets
import 'assets/scss/style.scss';
import { information } from 'services/auth';
import jwt from 'jsonwebtoken';
import { URL } from 'baseURL/baseURL';

let token = localStorage.getItem('token');
const jwt_secret = 'npCx5pnERXZ5vx3K88D7pTWKVBaHdwIPTJHJgtiNmHNDn6rXboEeErseqZyYw6QV';
console.log('token', token);
// console.log(token);
if (token) {
    jwt.verify(token, jwt_secret, function (err, decoded) {
        if (err) {
            console.log(err);
            if (err.name !== 'NotBeforeError') {
                localStorage.removeItem('token');
                token = null;
            }
        }
        // else {
        //     if (decoded.iss !== URL + '/login') {
        //         localStorage.removeItem('token');
        //         token = null;
        //     }
        // }
    });
}
const render = () => {
    ReactDOM.render(
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>,
        document.getElementById('root')
    );
};
if (token) {
    information()
        .then((res) => {
            console.log('resp');
            store.dispatch({ type: 'SET_LOGIN', payload: res.data });
        })
        .then(() => {
            render();
        });
} else {
    render();
}
serviceWorker.unregister();

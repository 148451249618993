import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, Card, Grid, InputAdornment, Select, Popper, MenuItem } from '@mui/material';

// third-party
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state';

// project imports
import Transitions from 'ui-component/extended/Transitions';

// assets
import { IconAdjustmentsHorizontal, IconSearch, IconX } from '@tabler/icons';
import { shouldForwardProp } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';

const OutlineInputStyle = styled(Select, { shouldForwardProp })(({ theme }) => ({
    width: 300,
    marginLeft: 16,
    paddingLeft: 16,
    paddingRight: 16,
    '& input': {
        background: 'transparent !important',
        paddingLeft: '4px !important'
    },
    [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginLeft: 4,
        background: '#fff'
    },
    [theme.breakpoints.down('lg')]: {
        width: 250
    },
    [theme.breakpoints.down('md')]: {
        width: '100%',
        marginLeft: 4,
        background: '#fff'
    }
}));

// ==============================|| SEARCH INPUT ||============================== //

const SearchSection = () => {
    const theme = useTheme();
    const [optionsUnit, setOptionsUnit] = useState([]);
    const dispatch = useDispatch();

    function renderOptions() {
        {
            return optionsUnit.map((name) => (
                <MenuItem key={name.id} value={name.id}>
                    {name.nome}
                </MenuItem>
            ));
        }
    }

    return (
        <>
            <Box
                sx={{
                    ml: 2,
                    mr: 3,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
                <OutlineInputStyle
                    id="input-search-header"
                    value={idUnit}
                    onChange={(e) => [
                        dispatch({ type: 'SET_UNIT_USER', payload: e.target.value }),
                        localStorage.setItem('unit', e.target.value)
                    ]}
                    placeholder="Pesquisar"
                    aria-describedby="search-helper-text"
                    inputProps={{ 'aria-label': 'weight' }}
                >
                    {renderOptions()}
                </OutlineInputStyle>
            </Box>
        </>
    );
};

export default SearchSection;

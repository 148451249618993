import { app } from './Api';

const getStaffs = async (page = '', qtdPerPage = '', nome = '', contrato_id = '', partner_id = '') => {
    return app.get(`efetivo?page=${page}&qtd_per_page=${qtdPerPage}&filter=${nome}&contrato_id=${contrato_id}&parceiro_id=${partner_id}`);
};
const getStaffPerId = async (id) => {
    return app.get(`efetivo/${id}`);
};
const updateStaff = async (id, data) => {
    return app.put(`efetivo/${id}`, data);
};
const changeStatus = async (id, data) => {
    return app.patch(`efetivo/${id}/change`, data);
};
const postStaff = async (data) => {
    return app.post(`efetivo`, data);
};

export { getStaffs, getStaffPerId, updateStaff, postStaff, changeStatus };

import { app } from './Api';
import { appblob } from './ApiBlob';

const updateDocument = async (id, data) => {
    return app.put(`documento/${id}`, data);
};

const createDocument = async (data) => {
    return app.post(`documento`, data);
};

const getFile = async (id, documento) => {
    return appblob.get(`documento/${documento}/arquivo/${id}`);
};

const getFiles = async (id) => {
    return app.get(`documento/${id}/arquivo`);
};

const uploadFile = async (data, config = null) => {
    return app.post(`documento/upload`, data, config);
};

const deleteFile = async (id, documento) => {
    return app.delete(`documento/${documento}/arquivo/${id}`);
};

export { updateDocument, createDocument, getFile, getFiles, uploadFile, deleteFile };

import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { Button, Modal, Box, Typography, Divider, Table, TableBody, TableContainer, TableRow, TableCell } from '@mui/material';
import { getFiles, getFile, deleteFile } from 'services/documents';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';

const DocumentFiles = ({ openModal, handleCloseModal, documentSelected, typeDocumentSelected, setSuccess, reloadDocuments }) => {
    const [files, setFiles] = useState([]);

    const handleClose = () => {
        handleCloseModal();
        setFiles([]);
    };

    const fetchData = async () => {
        if (documentSelected) {
            const res = await getFiles(documentSelected.id);
            setFiles(res.data);
        }
    };

    useEffect(() => {
        fetchData();
    }, [documentSelected]);

    const formatDate = (date) => {
        let time = new Date(date);
        return time.toLocaleString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    return (
        <Modal open={openModal} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    minWidth: 400,
                    maxWidth: 600,
                    boxShadow: 24,
                    p: 2,
                    borderRadius: '20px'
                }}
            >
                <Typography id="modal-title" variant="h3" component="h2">
                    {typeDocumentSelected?.nome}
                    <CloseIcon style={{ float: 'right', cursor: 'pointer', color: 'gray' }} onClick={handleClose} />
                </Typography>
                <br />
                <Divider />
                <TableContainer
                    style={{
                        overflowY: 'scroll',
                        height: 300
                    }}
                >
                    <Table>
                        <TableBody>
                            {files.map((file) => (
                                <TableRow key={nanoid()}>
                                    <TableCell>
                                        <Button
                                            onClick={async (id) => {
                                                getFile(file.id, documentSelected.id).then((resp) => {
                                                    const url = window.URL.createObjectURL(
                                                        new Blob([resp.data], { type: 'application/pdf' })
                                                    );
                                                    window.open(url, '_blank');
                                                    window.URL.revokeObjectURL(url);
                                                });
                                            }}
                                            style={{ fontSize: 14 }}
                                        >
                                            <DownloadIcon /> Baixar
                                        </Button>
                                    </TableCell>
                                    <TableCell style={{ fontSize: 12 }}>{formatDate(file.created_at)}</TableCell>
                                    <TableCell style={{ textAlign: 'right' }}>
                                        <Button
                                            disabled={documentSelected?.status == 'VALIDADO'}
                                            color="error"
                                            onClick={async () => {
                                                if (confirm('Deseja realmente excluir este arquivo?')) {
                                                    await deleteFile(file.id, documentSelected.id);
                                                    setSuccess('Arquivo excluído com sucesso!');
                                                    setTimeout(() => {
                                                        setSuccess('');
                                                    }, 3000);
                                                    fetchData();
                                                    reloadDocuments();
                                                }
                                            }}
                                        >
                                            <DeleteIcon style={{ fontSize: 20 }} />
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {files.length == 0 ? (
                                <TableRow>
                                    <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                                        <Typography variant="p" style={{ fontSize: 14 }}>
                                            Nenhum arquivo anexado
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                <></>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Modal>
    );
};

export default DocumentFiles;

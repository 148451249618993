// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import menuTechnical from 'menu-items/menuTechnical';
import { useSelector } from 'react-redux';

// ==============================|| SIDEBAR MENU LIST ||============================== //
const MenuList = () => {
    const id_role = useSelector((state) => state.auth.user.perfil_id);
    let nav = '';
    // if (id_role === 3) {
    //     nav = menuTechnical;
    // } else {
    //     nav = menuItem;
    // }
    nav = menuItem;
    const navItems = nav.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;

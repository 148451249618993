import { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
// material-ui
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import {
    Box,
    Button,
    FormHelperText,
    Grid,
    TextField,
    useMediaQuery,
    Alert,
    Snackbar,
    MenuItem,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Switch,
    InputLabel,
    Select
} from '@mui/material';
import * as Yup from 'yup';
import { useFormikContext, Formik, useFormik } from 'formik';
import FilterIcon from '@mui/icons-material/FilterAlt';
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import GroupIcon from '@mui/icons-material/Group';
// assets
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import Loading from 'components/Loading/Loading';
import { getPartners } from 'services/partners';
import { postContract, updateContract, getContracts } from 'services/contracts';
import TableStaffs from './components/TableStaffs';
import { useDispatch, useSelector } from 'react-redux';
// ===========================|| FIREBASE - REGISTER ||=========================== //

const Staffs = ({ ...others }) => {
    const params = useParams();
    const themeButton = createTheme({
        status: {
            danger: '#e53e3e'
        },
        palette: {
            primary: {
                main: '#0971f1',
                darker: '#053e85'
            },
            neutral: {
                main: '#64748B',
                contrastText: '#fff'
            }
        }
    });

    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const scriptedRef = useScriptRef();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [partners, setPartners] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [errorValidate, setErrorValidate] = useState({});
    const [partnerSelected, setPartnerSelected] = useState('');
    const [contractSelected, setContractSelected] = useState('');
    const isDisabled = params.action === 'view' ? true : false;
    const [openModalFilter, setOpenModalFilter] = useState(false);
    function getAllPartners() {
        getPartners().then((resp) => {
            setPartners(resp.data.data);
            if (resp?.data?.data?.length === 1) {
                setPartnerSelected(resp?.data?.data[0]?.id);
                dispatch({ type: 'SET_PARTNER_STAFF', payload: resp.data.data[0].id });
            }
        });
    }

    function getAllContracts() {
        getContracts('', '', '', partnerSelected, '').then((resp) => setContracts(resp.data.data));
    }

    useEffect(() => {
        getAllPartners();
    }, []);

    useEffect(() => {
        getAllContracts();
        if (partnerSelected !== '' || partnerSelected !== null) {
            getAllContracts();
            setContractSelected('');
        }
    }, [partnerSelected]);
    return (
        <>
            <Formik
                initialValues={{
                    parceiro_id: '',
                    contract_id: '',
                    numero: '',
                    tipo_documento: [],
                    submit: null
                }}
                enableReinitialize
                validationSchema={Yup.object().shape({
                    parceiro_id: Yup.number().required('Parceiro é obrigatório'),
                    numero: Yup.string().required('Contrato é obrigatório')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm, setFieldValue }) => {
                    try {
                        if (scriptedRef.current) {
                            setStatus({ success: true });
                            setSubmitting(false);
                        }
                        setLoading(true);
                        if (params.action === 'edit') {
                            updateContract(params.id, values)
                                .then((resp) => {
                                    setError('');
                                    setLoading(false);
                                    viewPerId();
                                    setSuccess(resp.data.success);
                                    setTimeout(() => {
                                        setSuccess('');
                                    }, 3000);
                                })
                                .catch((e) => {
                                    setLoading(false);
                                    setSuccess('');
                                    setErrorValidate(e.response.data.errorValidate);
                                    setError(e.response.data.error);
                                    setTimeout(() => {
                                        setError('');
                                    }, 3000);
                                });
                        } else {
                            values.ativo = 1;
                            postContract(values)
                                .then((resp) => {
                                    setError('');
                                    setLoading(false);
                                    setSuccess(resp.data.success);
                                    setTimeout(() => {
                                        resetForm();
                                        setSuccess('');
                                    }, 2000);
                                })
                                .catch((e) => {
                                    console.log(e);
                                    setLoading(false);
                                    setSuccess('');

                                    if (e.response) {
                                        const { errorValidate, errors, error } = e.response.data;

                                        if (errorValidate) {
                                            setErrorValidate(errorValidate);
                                        }

                                        if (errors) {
                                            const errorMessages = Object.values(errors).flat();
                                            setError(errorMessages[0]);
                                            for (const campo in errors) {
                                                if (errors.hasOwnProperty(campo)) {
                                                    const mensagensErroCampo = errors[campo];
                                                    console.log(`${campo}: ${mensagensErroCampo.join(', ')}`);
                                                }
                                            }
                                        }

                                        console.log('Status da resposta de erro:', e.response.status);
                                    } else if (e.request) {
                                        console.log('Requisição feita, mas sem resposta');
                                        setError('Erro: Sem resposta do servidor');
                                    } else {
                                        console.log('Erro ao processar a requisição:', e.message);
                                        setError('Ocorreu um erro ao processar a solicitação.');
                                    }
                                });
                        }
                    } catch (err) {
                        console.error(err);
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                    <>
                        {loading && (
                            <Grid container alignItems="center" justifyContent="center">
                                <MainCard style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Loading color="#015641" type="cubes" />
                                </MainCard>
                            </Grid>
                        )}
                        <MainCard spacing={gridSpacing} style={{ marginTop: 20 }}>
                            <Grid xs={12} md={12} sm={12} container>
                                <h1
                                    style={{
                                        font: 'normal normal bold 45px/54px',
                                        letterSpacing: '0px',
                                        color: 'var(--unnamed-color-015641)',
                                        color: 'black',
                                        opacity: 1
                                    }}
                                >
                                    <GroupIcon /> Efetivos
                                </h1>
                                <hr style={{ width: '100%', marginTop: 0 }}></hr>
                            </Grid>
                            {error || alert ? (
                                <Snackbar open={true} autoHideDuration={6000}>
                                    <Alert
                                        severity={error ? 'error' : success ? 'success' : ''}
                                        sx={{
                                            width: '100%',
                                            backgroundColor: error ? 'red' : success ? 'green' : 'orange',
                                            color: '#FFF'
                                        }}
                                    >
                                        {error ? error : success ? success : ''}
                                    </Alert>
                                </Snackbar>
                            ) : (
                                ''
                            )}
                            <div style={{ display: loading ? 'none' : 'block' }}>
                                <form noValidate onSubmit={handleSubmit} {...others}>
                                    <Grid container spacing={matchDownSM ? 0 : 2}>
                                        <Grid item xs={6} sm={6} sx={{ marginTop: 3 }}>
                                            <FormControl
                                                fullWidth
                                                variant="outlined"
                                                error={
                                                    Boolean(touched.parceiro_id && errors.parceiro_id) || Boolean(errorValidate.parceiro_id)
                                                }
                                            >
                                                <InputLabel id="partnerSelectLabel">Selecione um parceiro</InputLabel>
                                                <Select
                                                    labelId="partnerSelectLabel"
                                                    id="parceiro_id"
                                                    name="parceiro_id"
                                                    value={partners.length === 1 ? partners[0].id : values?.parceiro_id}
                                                    onChange={(event) => {
                                                        handleChange(event);
                                                        setFieldValue('contract_id', null);
                                                        setPartnerSelected(event.target.value);
                                                        dispatch({ type: 'SET_PARTNER_STAFF', payload: event.target.value });
                                                    }}
                                                    onBlur={handleBlur}
                                                    disabled={isDisabled || partners.length === 1}
                                                    label="Parceiro"
                                                >
                                                    <MenuItem value="">
                                                        <em>Selecione...</em>
                                                    </MenuItem>
                                                    {partners?.map((partner, index) => (
                                                        <MenuItem key={index} value={partner.id}>
                                                            {partner?.nome_fantasia}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {(touched.parceiro_id && errors.parceiro_id) || errorValidate.parceiro_id ? (
                                                    <FormHelperText>
                                                        {touched.parceiro_id && errors.parceiro_id
                                                            ? errors.parceiro_id
                                                            : errorValidate.parceiro_id
                                                            ? errorValidate.parceiro_id
                                                            : ''}
                                                    </FormHelperText>
                                                ) : null}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6} sm={6} sx={{ marginTop: 3 }}>
                                            <FormControl
                                                fullWidth
                                                variant="outlined"
                                                error={
                                                    Boolean(touched.contract_id && errors.contract_id) || Boolean(errorValidate.contract_id)
                                                }
                                            >
                                                <InputLabel id="contractSelectLabel">Selecione um Contrato</InputLabel>
                                                <Select
                                                    labelId="contractSelectLabel"
                                                    id="contract_id"
                                                    name="contract_id"
                                                    value={values?.contract_id}
                                                    onChange={(event) => {
                                                        handleChange(event);
                                                        console.log(event.target.value);
                                                        setContractSelected(event.target.value);
                                                    }}
                                                    onBlur={handleBlur}
                                                    disabled={isDisabled || !partnerSelected}
                                                    label="Empresa"
                                                >
                                                    <MenuItem value="">
                                                        <em>Selecione...</em>
                                                    </MenuItem>
                                                    {contracts?.map((contract, index) => (
                                                        <MenuItem key={index} value={contract.id}>
                                                            {contract?.numero}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {(touched.contract_id && errors.contract_id) || errorValidate.contract_id ? (
                                                    <FormHelperText>
                                                        {touched.contract_id && errors.contract_id
                                                            ? errors.contract_id
                                                            : errorValidate.contract_id
                                                            ? errorValidate.contract_id
                                                            : ''}
                                                    </FormHelperText>
                                                ) : null}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    {partnerSelected !== '' && (
                                        <>
                                            <hr style={{ width: '100%', marginTop: '30px' }}></hr>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <Button
                                                    endIcon={<FilterIcon />}
                                                    onClick={() => setOpenModalFilter(true)}
                                                    variant="contained"
                                                    style={{
                                                        width: '70px',
                                                        height: '30px',
                                                        padding: '6px',
                                                        fontSize: '12px',
                                                        marginTop: '10px',
                                                        backgroundColor: '#008B8B'
                                                    }}
                                                >
                                                    Filtros
                                                </Button>
                                            </div>
                                        </>
                                    )}

                                    <TableStaffs
                                        contractSelected={contractSelected}
                                        partnerSelected={partnerSelected}
                                        openModalFilter={openModalFilter}
                                        setOpenModalFilter={setOpenModalFilter}
                                    />

                                    <Grid container alignItems="end" justifyContent="end" sx={{ mt: 3 }}>
                                        <Grid item>
                                            <Box sx={{ mt: 2, mr: 3 }}>
                                                <ThemeProvider theme={themeButton}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            disabled={isSubmitting}
                                                            fullWidth
                                                            size="large"
                                                            type="button"
                                                            variant="contained"
                                                            color="neutral"
                                                            onClick={() => navigate(-1)}
                                                        >
                                                            Voltar
                                                        </Button>
                                                    </AnimateButton>
                                                </ThemeProvider>
                                            </Box>
                                        </Grid>
                                        {params.action === 'view' ? (
                                            <Grid item>
                                                <Box sx={{ mt: 2, mr: 3 }}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            // disabled={isSubmitting}
                                                            component={Link}
                                                            to={`/contrato/${params.id}/edit`}
                                                            fullWidth
                                                            size="large"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Editar
                                                        </Button>
                                                    </AnimateButton>
                                                </Box>
                                            </Grid>
                                        ) : (
                                            <Grid item>
                                                <Box sx={{ mt: 2, mr: 3 }}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            disabled={isSubmitting}
                                                            fullWidth
                                                            size="large"
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Salvar
                                                        </Button>
                                                    </AnimateButton>
                                                </Box>
                                            </Grid>
                                        )}
                                    </Grid>
                                    {errors.submit && (
                                        <Box sx={{ mt: 3 }}>
                                            <FormHelperText error>{errors.submit}</FormHelperText>
                                        </Box>
                                    )}
                                </form>
                            </div>
                        </MainCard>
                    </>
                )}
            </Formik>
        </>
    );
};

export default Staffs;

export const initialState = {
    page: 0,
    rowsPerPage: 10,
    nameContract: ''
};

// ==============================|| AUTH REDUCER ||============================== //

const contractsReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'SET_PAGE_CONTRACT':
            return {
                ...state,
                page: actions.payload
            };
        case 'SET_ROWS_PER_PAGE_CONTRACT':
            return {
                ...state,
                rowsPerPage: actions.payload
            };
        case 'SET_NAMECONTRACT_CONTRACT_FILTER':
            return {
                ...state,
                nameContract: actions.payload
            };
        case 'SET_CLEAR_CONTRACT_FILTER':
            return {
                ...state,
                nameContract: ''
            };
        default:
            return state;
    }
};

export default contractsReducer;

export const initialState = {
    page: 0,
    rowsPerPage: 10,
    nameStaff: '',
    partnerSelect: ''
};

// ==============================|| AUTH REDUCER ||============================== //

const staffsReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'SET_PAGE_STAFF':
            return {
                ...state,
                page: actions.payload
            };
        case 'SET_ROWS_PER_PAGE_STAFF':
            return {
                ...state,
                rowsPerPage: actions.payload
            };
        case 'SET_NAMESTAFF_STAFF_FILTER':
            return {
                ...state,
                nameStaff: actions.payload
            };
        case 'SET_CLEAR_STAFF_FILTER':
            return {
                ...state,
                nameStaff: '',
                partnerSelect: ''
            };
        case 'SET_PARTNER_STAFF':
            return {
                ...state,
                partnerSelect: actions.payload
            };
        default:
            return state;
    }
};

export default staffsReducer;
